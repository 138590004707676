(() => {
  // src/js/components/base/lucide.js
  (function() {
    "use strict";
    createIcons({
      icons,
      "stroke-width": 1.5,
      nameAttr: "data-lucide"
    });
  })();
})();
